import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "summaryCardsImage",
    "generateSummaryButton",
    "generatingSummaryButton",
    "CSSLoader",
    "GIFLoader"
  ];

  toggleLoadingState() {
    this.generateSummaryButtonTarget.classList.add("hidden");
    this.generatingSummaryButtonTarget.classList.remove("hidden");

    if (this.isSafari()) {
      this.CSSLoaderTarget.classList.remove("hidden");
    } else {
      this.summaryCardsImageTarget.classList.remove("bg-new-ai-summary-static");
      this.summaryCardsImageTarget.classList.add("bg-new-ai-summary-animated");

      this.GIFLoaderTarget.classList.remove("hidden");
    }
  }

  /**
   * Detects if browser is Safari using regex with negative look-arounds to exclude
   * Chrome, Edge, and Android browsers that include Safari in their user agent
   */
  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
}
