import TippyController from "./tippy_controller";

export default class extends TippyController {
  static targets = ["submenu"];

  get trigger() {
    return super.trigger || 'click'
  }

  get placement() {
    return super.placement || 'bottom-end'
  }

  get appendOptions() {
    if (this.element.hasAttribute("data-popover-append-popper-to-body")) {
      return { appendTo: document.body }
    } else {
      return {}
    }
  }

  get popperOptions() {
    const options = {
      popperOptions: {
        modifiers: []
      },
      maxWidth: 350,
      zIndex: 10000, // Ensure the popover is always on top e.g. in sidebar dropdown
    }

    if (this.element.hasAttribute("data-popover-minimal-offset")) {
      options.popperOptions.modifiers.push({
        name: "offset",
        options: {
          offset: [0, 1], // Set Popper offset to 1px away from the reference
        }
      })
    }

    if (this.element.hasAttribute("data-popover-max-width-1000")) {
      options.maxWidth = 1000 // Override the default 350px value
    }

    return options
  }

  get tippyOptions() {

    const hide = () => {
      // Prevent multiple calls as the event gets triggered
      // at a high rate
      window.removeEventListener('scroll', hide, true)
      this.hide()
    }

    return {
      ...super.tippyOptions,
      touch: true,
      ...this.appendOptions,
      ...this.popperOptions,
      onShow({ popper, reference }) {
        // Scroll events don't bubble so we need to grab them at the capture phase
        window.addEventListener('scroll', hide, true);

        // Ensures full-width popovers are at least as long as reference trigger (e.g., button)
        if (reference.hasAttribute("data-full-width-popover")) {
          popper.style.minWidth = reference.getBoundingClientRect().width + "px"
        }

        // Find all anchor/button elements with the "hidden" class within the tippy-box (popper)
        const hiddenElements = popper.querySelectorAll('a.hidden, button.hidden, span.hidden');
        if (hiddenElements.length > 0) {
          hiddenElements.forEach((hiddenElement) => {
            if (hiddenElement.classList.contains('show-on-below-sm-only')) {
              hiddenElement.classList.add('-sm:inline-flex');
            } else {
              hiddenElement.style.display = 'inline-flex'; // Change the display to inline-flex (.action--dropdown)
            }
          });
        } else {
          console.log('No <a> or <button> element with "hidden" class found within popper');
        }
      },
      onHide() {
        window.removeEventListener('scroll', hide, true)
      }
    }
  }

  clickOutside(event) {
    if (event.type == "clickoutside" && !event.detail.event.target.hasAttribute("data-popover-target")) {
      this.instance.hide()
    }
  }
}
